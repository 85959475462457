import React, {forwardRef, useMemo} from 'react';
import {Box, Sphere} from "@react-three/drei";

function Default(props, ref) {
    return (
        <group ref={ref} {...props}>
            {/* Head */}
            <Sphere args={[0.5, 32, 32]} position={[0, 2, 0]}>
                <meshStandardMaterial color="peachpuff"/>
            </Sphere>

            {/* Body */}
            <Box args={[1, 1, 0.5]} position={[0, 0.8, 0]}>
                <meshStandardMaterial color="blue"/>
            </Box>

            {/* Legs */}
            <Box args={[0.45, 1, 0.5]} position={[-0.25, -0.5, 0]}>
                <meshStandardMaterial color="darkblue"/>
            </Box>
            <Box args={[0.45, 1, 0.5]} position={[0.25, -0.5, 0]}>
                <meshStandardMaterial color="darkblue"/>
            </Box>

            {/* Arms */}
            <Box args={[0.35, 1, 0.5]} position={[-0.8, 1, 0]}>
                <meshStandardMaterial color="lightblue"/>
            </Box>
            <Box args={[0.35, 1, 0.5]} position={[0.8, 1, 0]}>
                <meshStandardMaterial color="lightblue"/>
            </Box>
        </group>
    );
}

export default forwardRef(Default);