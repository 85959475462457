import React, {forwardRef, useMemo} from 'react';
import {Gltf, useGLTF} from "@react-three/drei";
import {SkeletonUtils} from "three-stdlib";

function Ghost(props, ref) {
    const { scene, materials, animations } = useGLTF(
        "/figures/ghost.glb"
    );
    const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
    return (
        <>
            <primitive ref={ref} object={clone} {...props} />
        </>
    );
}

export default forwardRef(Ghost);