import React from 'react';
import {Gltf} from "@react-three/drei";
import {RigidBody} from "@react-three/rapier";

function House1(props) {
    return (
        <RigidBody type="fixed" colliders="trimesh" {...props}>
            <Gltf
                  src="/objects/house.glb"/>
        </RigidBody>
    );
}

export default House1;