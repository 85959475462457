import {atom, useAtom} from "jotai";
import {useEffect} from "react";
import {io} from "socket.io-client";

export const socket = io("http://103.148.15.56:4000");
export const playersAtom = atom({});

const SocketManager = () => {
    const [players, setPlayers] = useAtom(playersAtom);
    useEffect(() => {
        function onPlayerConnected({player}) {
            console.log("onPlayerConnected", player);
            setPlayers((prevPlayers) => ({
                ...prevPlayers,
                [player.sessionId]: player,
            }));
        }

        function onPlayerDisconnected({sessionId}) {
            console.log("onPlayerDisconnected", sessionId);
            setPlayers((prevPlayers) => {
                const {[sessionId]: _, ...remainingPlayers} = prevPlayers;
                return remainingPlayers;
            });
        }

        function onPlayers({players}) {
            console.log("onPlayers", players);
            setPlayers(players);
        }

        function onMove({sessionId, position}) {
            setPlayers((prevPlayers) => ({
                ...prevPlayers,
                [sessionId]: {
                    ...prevPlayers[sessionId],
                    position: position,
                }
            }));
        }

        socket.on("playerConnected", onPlayerConnected);
        socket.on("playerDisconnected", onPlayerDisconnected);
        socket.emit("players");
        socket.on("players", onPlayers);
        socket.on("move", onMove);
        return () => {
            socket.off("playerConnected", onPlayerConnected);
            socket.off("playerDisconnected", onPlayerDisconnected);
            socket.off("players", onPlayers);
            socket.off("move", onMove);
        };
    }, []);
};

export default SocketManager;