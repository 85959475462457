import {Canvas} from '@react-three/fiber'
import "./App.css";
import SocketManager from "./sockets/SocketManager";
import Experience from "./components/Experience";

function App() {
    return (
        <>
            <SocketManager />
            <Canvas shadows onPointerDown={(e) => e.target.requestPointerLock()}>
                <Experience />
            </Canvas>
            <img className="controlKeys" src="/controls.png" alt="control keys"/>
        </>
    )
}

export default App;