import {useEffect, useState} from "react";
import Home from "./Maps/Home";
import Test from "./Maps/Test";

const maps = [
    <Home />,
    <Test />
];

function Experience() {
    const [mapId, setMapId] = useState(0);
    const [mapRender, setMapRender] = useState(null);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setMapId(1);
    //     }, 2000);
    // }, []);

    useEffect(() => {
        setMapRender(maps[mapId]);
    }, [mapId]);

    return (
        <>
            {mapRender && mapRender}
        </>
    )
}

export default Experience;