import React from 'react';
import {Fisheye} from "@react-three/drei";
import Night1 from "../Skies/Night1";
import Light1 from "../Lights/Light1";
import {Physics} from "@react-three/rapier";
import Ground1 from "../Grounds/Ground1";
import House1 from "../Objects/House/House1";
import MyPlayer from "../Players/MyPlayer";
import PlayersOnMap from "../Players/PlayersOnMap";

function Home(props) {
    return (
        <Fisheye zoom={0.4}>
            <Night1/>
            <Light1/>
            <Physics timeStep="vary">
                <>
                    <Ground1 />
                    <House1 position={[0,0,0]} rotation={[-Math.PI / 2, 0, 0]} castShadow receiveShadow scale={0.11} />
                </>
                <>
                    <MyPlayer/>
                    <PlayersOnMap/>
                </>
            </Physics>
        </Fisheye>
    );
}

export default Home;