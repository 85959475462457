import React from 'react';
import {CuboidCollider, RigidBody} from "@react-three/rapier";
import ground from "../../assets/a.png"
import {useTexture} from "@react-three/drei";
import * as THREE from "three";

function Ground1(props) {
    const texture = useTexture(ground)
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping

    return (
        <RigidBody {...props} type="fixed" colliders={false}>
            <mesh receiveShadow position={[0, -3, 0]} rotation-x={-Math.PI / 2}>
                <planeGeometry args={[10000, 10000]} />
                <meshStandardMaterial map={texture} map-repeat={[1000, 1000]} />
            </mesh>
            <CuboidCollider args={[1000, 2, 1000]} position={[0, -4, 0]} />
        </RigidBody>
    );
}

export default Ground1;