import React from 'react';
import {RigidBody} from "@react-three/rapier";
import Figure from "../Figures/Figure";
import * as THREE from "three";
import {playersAtom} from "../../sockets/SocketManager";
import {useAtom} from "jotai/index";

function PlayersOnMap(props) {
    const [players] = useAtom(playersAtom);
    return (
        <>
            {Object.entries(players).map(([sessionId, player]) => (
                // <RigidBody key={sessionId} type="fixed" colliders="trimesh">
                    <Figure key={sessionId} figure_id="ghost" castShadow receiveShadow scale={0.315}
                            position={new THREE.Vector3(player.position.x, player.position.y, player.position.z)}
                    />
                // </RigidBody>
            ))}
        </>
    );
}

export default PlayersOnMap;