import React from 'react';

function Light1(props) {
    return (
        <>
            <directionalLight intensity={0.7} castShadow shadow-bias={-0.0004} position={[-20, 20, 20]}>
                <orthographicCamera attach="shadow-camera" args={[-20, 20, 20, -20]}/>
            </directionalLight>
            <ambientLight intensity={0.2}/>
        </>
    );
}

export default Light1;