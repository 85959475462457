import React, {useEffect, useRef, useState} from 'react';
import {KeyboardControls} from "@react-three/drei";
import Controller from "ecctrl";
import Figure from "../Figures/Figure";
import * as THREE from "three";
import {socket} from "../../sockets/SocketManager";
import {useFrame} from "@react-three/fiber";
import {randInt} from "three/src/math/MathUtils";

const keyboardMap = [
    {name: 'forward', keys: ['ArrowUp', 'KeyW']},
    {name: 'backward', keys: ['ArrowDown', 'KeyS']},
    {name: 'leftward', keys: ['ArrowLeft', 'KeyA']},
    {name: 'rightward', keys: ['ArrowRight', 'KeyD']},
    {name: 'jump', keys: ['Space']},
    {name: 'run', keys: ['Shift']},
]

var oldPosition = new THREE.Vector3(Infinity, Infinity, Infinity);
function MyPlayer(props) {
    const figureRef = useRef(null);

    const handleRef = (ref) => {
        figureRef.current = ref;
    };

    const onMove = () => {
        if(!figureRef.current) return;
        const newPosition = new THREE.Vector3();
        figureRef.current.getWorldPosition(newPosition);
        if(oldPosition.distanceTo(newPosition) > 0.1){
            socket.emit("move", {
                position: newPosition,
            });
            oldPosition = newPosition;
        }
    }

    useFrame(() => {
        onMove();
    });

    return (
        <KeyboardControls map={keyboardMap}>
            <Controller maxVelLimit={5}>
                <Figure forwardRef={handleRef} figure_id="ghost" castShadow receiveShadow scale={0.315}
                        position={new THREE.Vector3(0, -0.55, 0)}/>
            </Controller>
        </KeyboardControls>
    );
}

export default MyPlayer;