import React, {forwardRef, useEffect, useMemo, useRef} from 'react';
import Ghost from './Ghost';
import Default from "./Default";
import {useFrame} from "@react-three/fiber";
import * as THREE from "three";

const figureMap = {
    ghost: Ghost,
};
const MOVEMENT_SPEED = 0.1;

function Figure({figure_id, forwardRef, ...props}) {
    const figureRef = useRef(null);
    const position = useMemo(() => props.position, []);

    useEffect(() => {
        if (forwardRef) {
            forwardRef(figureRef.current);
        }
    }, [forwardRef]);

    useFrame(() => {
        if (figureRef.current.position.distanceTo(props.position) > 0.1) {
            const direction = figureRef.current.position
                .clone()
                .sub(props.position)
                .normalize()
                .multiplyScalar(MOVEMENT_SPEED);
            figureRef.current.position.sub(direction);

            if (
                new THREE.Vector3(
                    figureRef.current.position.x,
                    0,
                    figureRef.current.position.z
                ).distanceTo(new THREE.Vector3(
                    props.position.x,
                    0,
                    props.position.z
                )) > 0.07
            ) {
                const targetPosition = new THREE.Vector3(props.position.x, figureRef.current.position.y, props.position.z);
                figureRef.current.lookAt(targetPosition);
            }
            //animation
        } else {
            //animation
        }
    });

    const FigureComponent = figureMap[figure_id] || Default;
    return <FigureComponent ref={figureRef} {...props} position={position} dispose={null}/>;
}

export default Figure;
